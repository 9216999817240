import { Section } from 'components/MenuSection'
import { MenuSectionFragment } from 'lib/gql.types'
import { OrderingTypeSlug } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'

import { NoFilterResults } from './NoFilterResults'

interface BaseSectProps {
  isWaitTimeActive: boolean
  venueSlug: string
  menuCategorySlug: string
  venueName: string
  orderingTypeSlug: OrderingTypeSlug
}

interface SectionsProps extends BaseSectProps {
  sections: MenuSectionFragment[]
}

const Sections = observer(
  ({
    isWaitTimeActive,
    venueSlug,
    orderingTypeSlug,
    sections,
    ...props
  }: PropsWithChildren<SectionsProps>) => {
    return (
      <>
        {sections.length === 0 && <NoFilterResults />}
        {sections.map((section, index) => {
          return (
            <Section
              key={section.slug}
              isWaitTimeActive={isWaitTimeActive}
              sectionIndex={index}
              section={section}
              venueSlug={venueSlug}
              orderingTypeSlug={orderingTypeSlug}
              {...props}
            />
          )
        })}
      </>
    )
  },
)

export default Sections

Sections.displayName = 'Sections'
