import { ClockFilledIcon } from '@mr-yum/frontend-ui'
import { MenuBadge } from 'components/Menu/MenuBadge'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface FlexibleWaitTimesLabelProps {
  isItemWaitTime?: boolean
  estimationInMinutes?: number | null
  category?: string
}

const generateItemWaitTimeMessage = ({
  estimationInMinutes,
}: {
  estimationInMinutes?: number | null
}) => {
  if (!estimationInMinutes) {
    return (
      <FormattedMessage defaultMessage="Longer wait on this item" id="ZnzlVG" />
    )
  }

  const waitTimeHours = Math.floor(estimationInMinutes / 60)
  const waitTimeMinutes = estimationInMinutes % 60

  if (waitTimeMinutes === 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour wait on this item"
        id="mfOj9L"
        values={{ waitTimeHours }}
      />
    )
  }

  if (waitTimeHours > 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour {waitTimeMinutes} min wait on this item"
        id="WniXzV"
        values={{ waitTimeHours, waitTimeMinutes }}
      />
    )
  }

  return (
    <FormattedMessage
      defaultMessage="{estimationInMinutes} min wait on this item"
      id="D6YCVh"
      values={{ estimationInMinutes }}
    />
  )
}

const generateMenuSectionWaitTimeMessage = ({
  estimationInMinutes,
  category,
}: {
  estimationInMinutes?: number | null
  category: string
}) => {
  const categoryString = category ? ` on ${category}` : ''
  if (!estimationInMinutes) {
    return (
      <FormattedMessage
        defaultMessage="Longer wait{category}"
        id="xgDxzK"
        values={{ category: categoryString }}
      />
    )
  }

  const waitTimeHours = Math.floor(estimationInMinutes / 60)
  const waitTimeMinutes = estimationInMinutes % 60

  if (waitTimeMinutes === 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour wait{category}"
        id="HelRpj"
        values={{ waitTimeHours, category: categoryString }}
      />
    )
  }

  if (waitTimeHours > 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour {waitTimeMinutes} min wait{category}"
        id="xqQmMk"
        values={{ waitTimeHours, waitTimeMinutes, category: categoryString }}
      />
    )
  }

  return (
    <FormattedMessage
      defaultMessage="{estimationInMinutes} min wait{category}"
      id="+JyJyv"
      values={{ estimationInMinutes, category: categoryString }}
    />
  )
}

export const FlexibleWaitTimesLabel = ({
  isItemWaitTime,
  estimationInMinutes,
  category = '',
}: FlexibleWaitTimesLabelProps) => {
  const waitTimeMessage = isItemWaitTime
    ? generateItemWaitTimeMessage({ estimationInMinutes })
    : generateMenuSectionWaitTimeMessage({
        estimationInMinutes,
        category,
      })

  return (
    <p className="flex items-center justify-center text-foreground my-label-sm">
      <ClockFilledIcon className="mr-1 flex-shrink-0" height={16} width={16} />
      {waitTimeMessage}
    </p>
  )
}

export const FlexibleWaitTimesBadge = ({
  isItemWaitTime,
  estimationInMinutes,
  category = '',
}: FlexibleWaitTimesLabelProps) => {
  const waitTimeMessage = isItemWaitTime
    ? generateItemWaitTimeMessage({ estimationInMinutes })
    : generateMenuSectionWaitTimeMessage({
        estimationInMinutes,
        category,
      })

  return (
    <MenuBadge>
      <ClockFilledIcon className="mr-1 flex-shrink-0" height={16} width={16} />
      {waitTimeMessage}
    </MenuBadge>
  )
}
