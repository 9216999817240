import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { ClientOnly } from 'components/Common/ClientOnly'
import { NotFound } from 'components/Common/NotFound'
import { JsonLd } from 'components/JsonLd/JsonLd'
import { VenueToJsonLd } from 'components/JsonLd/utils'
import { MenuLayout } from 'components/Menu/MenuLayout'
import Sections from 'components/Menu/Sections'
import { ItemModal } from 'components/MenuItemModal/ItemModal'
import { SectionSkeleton } from 'components/MenuSection/SectionSkeleton'
import { Meta } from 'components/Meta/Meta'
import { VenueClosedBanner } from 'components/Venue/Blocks/VenueClosedBanner'
import { randomizeCategories } from 'components/Venue/utils/categoryUtils'
import { CategoryMenuBarProvider } from 'contexts/CategoryMenuBarContext'
import { RewardsProvider } from 'contexts/RewardsContext'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useCartMenu } from 'hooks/useCart'
import {
  GuestWaitTime,
  OrderingType,
  useMenuQuery,
  useMenuVenueQuery,
} from 'lib/gql'
import { MenuItemPartsFragment, MenuSectionFragment } from 'lib/gql.types'
import find from 'lodash/fp/find'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import times from 'lodash/times'
import { observer } from 'mobx-react-lite'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'

import { BookNow } from './BookNow'
import { useFilteredMenuSections } from './hooks/useFilteredMenuSections'
import { NoMenuCategory } from './NoMenuCategory'
import { getCartItemCount } from './utils'

interface Props {
  category: string
  itemSlug?: string
  noModal?: boolean
}

export interface MenuSectionWaitTime extends GuestWaitTime {
  id: string
}

export const PreorderingContext = React.createContext(false)

export const MenuPage: NextPage<Props> = observer(({ category, noModal }) => {
  const { query } = useRouter()
  const { itemSlug } = query
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const [
    { data: venueData, fetching: venueFetching, error: venueError },
    venueRefetch,
  ] = useMenuVenueQuery({
    variables: {
      venueSlug,
    },
  })

  const venue = venueData?.guestVenue

  const { cart: cartData, fetching: cartDataFetching } = useCartMenu({
    venueSlug,
    orderingType,
    pause: !venue,
  })

  const itemCount = getCartItemCount(cartData)

  const [{ data, fetching, error }, refetch] = useMenuQuery({
    variables: {
      venueSlug,
      categorySlug: category,
      orderingType,
      tableNumber: cartData?.tableNumber ?? undefined,
      tableArea: cartData?.tableArea ?? undefined,
      orderingWindowStartDate: cartData?.orderingWindowStartDate ?? undefined,
      priceLevel: '',
    },
    pause: cartDataFetching,
  })

  const menuCategory = data?.guestMenuCategory

  const filteredMenuCategories = useMemo(
    () =>
      randomizeCategories(
        data?.guestMenuCategories ?? [],
        venue?.multiVendorEnabled,
      ),
    [data, venue?.multiVendorEnabled],
  )

  const sections: MenuSectionFragment[] = useFilteredMenuSections(menuCategory)

  const selectedMenuItem: MenuItemPartsFragment | undefined = useMemo(
    () =>
      flow(
        get('menuSections'),
        map('menuItems'),
        flatten,
        find({ venueSlug: itemSlug }),
      )(menuCategory),
    [itemSlug, menuCategory],
  )

  const menuCategoryLoading = isServer || fetching || cartDataFetching

  if (!venueFetching && venueError) {
    return <NotFound error={venueError} clearError={venueRefetch} />
  }
  if (!venueFetching && !venue) {
    return <NotFound message="Venue not found" />
  }
  if (!fetching && error) {
    return <NotFound error={error} clearError={refetch} />
  }

  const activeOrderingTypeSettings = venue?.orderingTypes.find(
    (setting) => setting.orderingType === orderingType,
  )

  const canPreorder =
    [OrderingType.Delivery, OrderingType.PickUp, OrderingType.Counter].indexOf(
      orderingType,
    ) > -1 && activeOrderingTypeSettings?.enabled

  return (
    <PreorderingContext.Provider value={canPreorder!}>
      <RewardsProvider>
        <CategoryMenuBarProvider>
          {venue && (
            <Meta
              title={`${venue.name} ${
                menuCategory ? `${menuCategory.name} menu` : 'menu'
              } - Explore our menu with photos of every item. Order & pay online.`}
              description={`${
                menuCategory ? menuCategory.name : 'Menu'
              } - Explore the mobile menu at ${
                venue.name
              }. Order & pay online with me&u.`}
              openGraph={{
                title: `${venue.name} ${
                  menuCategory ? `${menuCategory.name} menu` : 'menu'
                } - Explore our menu with photos of every item. Order & pay online.`,
                description: `${
                  menuCategory ? menuCategory.name : 'Menu'
                } - Explore the mobile menu at ${
                  venue.name
                }. Order & pay online with me&u.`,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                site_name: 'me&u',
              }}
            />
          )}
          <MenuLayout
            venue={venue}
            menuCategory={menuCategory}
            menuCategories={filteredMenuCategories}
            category={category}
            itemCount={itemCount}
            loading={menuCategoryLoading}
            loaderComponent={times(4, (i) => (
              <SectionSkeleton key={i} />
            ))}
          >
            <div className="-mx-4 -mt-5 mb-5 md:-mx-5 md:-mt-8 md:mb-8">
              <VenueClosedBanner venue={venue} orderingType={orderingType} />
            </div>
            <ClientOnly>
              {menuCategory ? (
                <Sections
                  isWaitTimeActive={menuCategory.isWaitTimeActive}
                  venueName={venue?.name || ''}
                  venueSlug={venueSlug}
                  menuCategorySlug={category}
                  orderingTypeSlug={orderingTypeSlug}
                  sections={sections}
                />
              ) : (
                <NoMenuCategory
                  venueSlug={venueSlug}
                  orderingTypeSlug={orderingTypeSlug}
                />
              )}
            </ClientOnly>
            {venue?.bookingLink && (
              <BookNow
                bookingLink={venue?.bookingLink}
                isIframe={false}
                venueSlug={venueSlug}
              />
            )}
          </MenuLayout>

          {!noModal && (
            <ItemModal
              itemSlug={String(itemSlug)}
              isOpen={!!itemSlug}
              hasImage={!!selectedMenuItem?.image}
            />
          )}

          {venue && (
            <ClientOnly>
              <JsonLd data={VenueToJsonLd(venue, sections)} />
            </ClientOnly>
          )}
        </CategoryMenuBarProvider>
      </RewardsProvider>
    </PreorderingContext.Provider>
  )
})

MenuPage.displayName = 'MenuPage'
